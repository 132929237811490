import React, { useState, useMemo, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import equal from 'deep-equal';
import commonStyles from '../../styles';
import ResponseRegisterName from './parts/responseRegisterName';
import ResponseRegisterContact from './parts/responseRegisterContact';
import ResponseRegisterAddress from './parts/responseRegisterAddress';
import ResponseRegisterFamily from './parts/responseRegisterFamily';
import ResponseRegisterWorkPlace from './parts/responseRegisterWorkPlace';
import ResponseRegisterBudget from './parts/responseRegisterBudget';
import ResponseRegisterHope from './parts/responseRegisterHope';
import ResponseRegisterPresentCondition from './parts/responseRegisterPresentCondition';
import ResponseRegisterResponse from './parts/responseRegisterResponse';
import ResponseRegisterInHouse from './parts/responseRegisterInHouse';
import ResponseRegisterConfirm from './parts/responseRegisterConfirm';
import CustomMainSelect from '../../eleCommon/customMainSelect';
import CustomBlankSelect from '../../eleCommon/customBlankSelect';
import CustomBackSelect from '../../eleCommon/customBackSelect';
import CustomMainSelectPlaceholder from '../../eleCommon/customPlaceholderSelect';

import customerRegisterApi from '../../../apis/customer/customerRegisterApi';
import customerUpdateApi from '../../../apis/customer/customerUpdateApi';
import resHouseDeleteApi from '../../../apis/resHouse/resHouseDeleteApi';
import customerCallApi from '../../../apis/customer/customerApi';
import customerResponsesApi from '../../../apis/customer/customerResponses';
import resHouseGetApi from '../../../apis/resHouse/resHouseGetApi';

import { crmPageActions } from '../../../store/crmPage';
import { isOpen } from '../../../store/common/apiMessageSlice';

import { useMailVerification } from '../../crmMain/useMailVerification';

import { useLoading } from '../../../hooks';

import { classNames, dateFormatter, getPromiseSettledResult } from '../../../commonFunction';

import { UPDATE_TYPE_CODE, CUSTOMER_STATUS_CODE } from '../../../constants/apiParameterCode';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 1063,
    width: 1063,
    background: '#fff',
  },
  header: {
    padding: 0,
    marginBottom: 8,
  },
  content: {
    background: '#F3F3F3',
    padding: 16,
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  contentId: {
    width: 160,
    background: '#C8C8C8',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  contentIdNo: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    marginLeft: 4,
  },
  footer: {
    padding: '16px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn1: {
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    color: theme.palette.primaryColor,
    minWidth: 'auto',
    marginRight: 16,
    padding: '8px 22px',
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  btn2: {
    padding: '8px 22px',
  },
  clicable: { cursor: 'pointer' },
}));

const clone = (value) => JSON.parse(JSON.stringify(value));

function formatRequestSpKey(data) {
  // 電話番号のハイフンの処理
  const phoneNumbers = [
    data.phoneNumber1,
    data.phoneNumber2,
    data.phoneNumber3,
    data.phoneNumber4,
  ];
  // 電話番号に数字以外の文字が存在する
  if (/\D/.test(phoneNumbers.join(''))) {
    const res = { ...data };
    Object.keys(data).forEach((key) => {
      if (/^phoneNumber\d$/.test(key) && data[key]) {
        res[key] = data[key].replace(/\D/g, '');
      }
    });
    return res;
  }
  return data;
}

const responseTypeSet = new Set([
  'largeResponseTypeId',
  'largeResponseTypeName',
  'mediumResponseTypeId',
  'mediumResponseTypeName',
  'smallResponseTypeId',
  'smallResponseTypeName',
  'fineResponseTypeId',
  'fineResponseTypeName',
]);
/**
 * 更新のあったkeyのみをAPIに送るため比較した結果を返す。
 * 必須プロパティも加える。
 */
const needUpdateApiRequestProperties = (before, after) => {
  let isChangeResponseType = false;
  const data = Object.keys(before).reduce((result, key) => {
    if (!equal(before[key], after[key])) {
      result[key] = after[key];
      if (responseTypeSet.has(key)) isChangeResponseType = true;
    }
    return result;
  }, {});
  // 画面に表示していない情報
  data.updateType = after.updateType;
  data.getDataAt = after.getDataAt;
  data.isNotCheckUpdate = after.isNotCheckUpdate || 1;

  // 反響媒体は一つでも変更されれば全てのデータをapiにリクエストする
  if (isChangeResponseType) {
    data.largeResponseTypeId = after.largeResponseTypeId;
    data.largeResponseTypeName = after.largeResponseTypeName;
    data.mediumResponseTypeId = after.mediumResponseTypeId;
    data.mediumResponseTypeName = after.mediumResponseTypeName;
    data.smallResponseTypeId = after.smallResponseTypeId;
    data.smallResponseTypeName = after.smallResponseTypeName;
    data.fineResponseTypeId = after.fineResponseTypeId;
    data.fineResponseTypeName = after.fineResponseTypeName;
  }
  return formatRequestSpKey(data);
};

export default function ResponseRegister(props) {
  const dispatch = useDispatch();

  const {
    user,
    inquiry,
    response,
    id,
    updateType,
    isAllCustomerAdmin,
    isLeadersMemoUse,
    // isClaim,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();
  const { addLoading, removeLoading } = useLoading();

  const { verifyMail, rerenderKey, rerender } = useMailVerification(user);

  const [errorSet, setErrorSet] = useState(new Set());

  // 新規作成後、続けて編集する際に使用
  const tmpCustomerId = useRef(null);

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };

  const cloneUser = () => {
    const tmp = clone(user);
    if (updateType !== 3) return tmp;
    // 再反の場合は反響媒体を初期化
    // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4818#comment-189116427
    tmp.largeResponseTypeId = undefined;
    tmp.largeResponseTypeName = undefined;
    tmp.mediumResponseTypeId = undefined;
    tmp.mediumResponseTypeName = undefined;
    tmp.smallResponseTypeId = undefined;
    tmp.smallResponseTypeName = undefined;
    tmp.fineResponseTypeId = undefined;
    tmp.fineResponseTypeName = undefined;
    // 追わない 2 → 追客中 1
    if (tmp.customerStatusCode === CUSTOMER_STATUS_CODE.NOT_CHASE) {
      tmp.customerStatusCode = CUSTOMER_STATUS_CODE.CHASING;
    }
    return tmp;
  };
  const [detail, setDetail] = useState(cloneUser());
  const isClaim = detail.customerStatusCode === 11;

  const [reactionDay, setReactionDay] = useState({
    effectAt: dateFormatter(new Date(), 'YYYY/MM/DD hh:mm:ss'),
  });

  // 検索項目
  const updateKeyword = useCallback((keyword, isForceSearch) => {
    dispatch(
      crmPageActions.setSearchKeyword({ searchWord: keyword, isForceSearch }),
    );
  }, []);

  const searchCustomerByKeyword = (detail1, args, isForceSearch = false) => {
    if (!args.length) return;
    const searchKeyword = args.map((key) => detail1[key] ?? '').join('');
    updateKeyword(searchKeyword, isForceSearch);
  };

  const searchByCustomerId = () => {
    if (id === '-') return;
    updateKeyword(String(id));
  };

  const searchByWebMemberId = () => {
    if (!detail.webs[0]) return;
    updateKeyword(String(detail.webs[0].memberId));
  };

  const disabled = useMemo(() => {
    return !detail.lastName
      || !(detail.userId || detail.divisionId)
      || !detail.effectAt
      || !detail.largeResponseTypeId
      || !(id || detail.acquisitionId || detail.acquisitionMemo);
  }, [
    detail.lastName,
    detail.userId,
    detail.divisionId,
    detail.effectAt,
    detail.largeResponseTypeId,
    detail.acquisitionId,
    detail.acquisitionMemo,
  ]);

  // FIXME: コンポーネントとして切り出したい
  const getComponent = (data) => {
    switch (data.type) {
      case 'select':
        return <CustomMainSelect initial={data} />;
      case 'selectBlank':
        return <CustomBlankSelect initial={data} />;
      case 'selectBack':
        return <CustomBackSelect initial={data} />;
      case 'selectPlaceholder':
        return <CustomMainSelectPlaceholder initial={data} />;
      default:
        return <div>対応しない要素です</div>;
    }
  };

  const selectedInquiryRef = useRef();

  // 問合せ物件登録
  const addInquiryProducts = () => {
    const newProducts = inquiry.data.products.filter((item) => item.inquiryProductId < 0);
    if (!newProducts.length) return null;
    const params = newProducts.map((item => {
      // inquiredAt(問い合わせ日)はデフォルト
      const newParam = {
        inquiredAt: item.inquiredAt,
      };
      // 物件選択ウィンドウ(検索形式)から物件を選択した場合
      if (item && item.siteId) {
        newParam.siteId = item.siteId;
      }
      // 物件番号を直接入力した場合
      if (item && item.buildingId !== 0) {
        newParam.buildingId = item.buildingId;
      }
      return newParam;
    }));
    return params;
  };

  // 編集内容を破棄
  const destructionStart = () => dispatch(crmPageActions.resetCustomerData());
  // 引き続き編集
  const onOkRegisterWindow = async () => {
    addLoading();
    const promises = [
      // 【customer001】顧客情報取得
      customerCallApi([tmpCustomerId.current], 0, 0, 1),
      // 【customer010】顧客反響履歴一覧取得API
      customerResponsesApi(tmpCustomerId.current, 1),
      // 【resHouse001】問合せ物件検索API
      resHouseGetApi(tmpCustomerId.current, 1),
    ];

    const [customerApi, responses, inquiries] = await Promise.allSettled(promises);

    dispatch(crmPageActions.clickedCustomerUpdateButton({
      customer: getPromiseSettledResult(customerApi)?.data.customers[0],
      response: getPromiseSettledResult(responses),
      inquiry: getPromiseSettledResult(inquiries),
    }));
    removeLoading();
    // dispatch(
    //   crmPageActions.keepEditAtCompletedResponseRegister({
    //     pageUserState: detail,
    //   }),
    // );
  };
  // 編集を終了
  const onCancelRegisterWindow = () => dispatch(crmPageActions.resetCustomerData());

  // メールアドレスキャンセル処理
  const cancelMailChange = (original) => {
    setDetail({
      ...detail,
      ...original,
    });
    // 画面更新
    rerender();
  };
  // 保存
  const keepStart = async () => {
    addLoading();
    const newData = needUpdateApiRequestProperties(user, detail);
    if ('wishArea' in newData && !('wishAreaCode' in newData)) {
      newData.wishAreaCode = detail.wishAreaCode;
    }
    // 問い合わせ物件を追加
    const products = addInquiryProducts();
    if (products) newData.inquiryProducts = products;
    try {
      await customerUpdateApi(id, newData);
      dispatch(crmPageActions.resetCustomerData());
      dispatch(crmPageActions.researchCustomer());
    } finally {
      removeLoading();
    }
  };
  // 再販
  const rebellionStart = async () => {
    addLoading();
    const newData = needUpdateApiRequestProperties(user, detail);
    if (('wishArea' in newData) && !('wishAreaCode' in newData)) {
      newData.wishAreaCode = detail.wishAreaCode;
    }
    // 問い合わせ物件を追加
    const products = addInquiryProducts();
    if (products) newData.inquiryProducts = products;
    try {
      await customerUpdateApi(id, newData);
      dispatch(crmPageActions.resetCustomerData());
      dispatch(crmPageActions.researchCustomer());
    } finally {
      removeLoading();
    }
  };
  // 戻し
  const returnStart = async () => {
    addLoading();
    const newData = needUpdateApiRequestProperties(user, detail);
    if (('wishArea' in newData) && !('wishAreaCode' in newData)) {
      newData.wishAreaCode = detail.wishAreaCode;
    }
    // 問い合わせ物件を追加
    const products = addInquiryProducts();
    if (products) newData.inquiryProducts = products;
    try {
      await customerUpdateApi(id, newData);
      dispatch(crmPageActions.resetCustomerData());
      dispatch(crmPageActions.researchCustomer());
    } finally {
      removeLoading();
    }
  };
  // 問い合わせ物件の削除
  const deleteStart = async () => {
    if (selectedInquiryRef.current.inquiryProductId > 0) {
      addLoading();
      // idが負数の場合、現在追加したデータ(DBに登録されていないデータ)なので削除APIを叩かない
      await resHouseDeleteApi(selectedInquiryRef.current.inquiryProductId);
      removeLoading();
    }
    dispatch(
      crmPageActions.deletedInquiryProducts({
        inquiryProductId: selectedInquiryRef.current.inquiryProductId,
        buildingId: selectedInquiryRef.current.buildingId,
        siteId: selectedInquiryRef.current.siteId,
      }),
    );
  };
  const registerInquiryProduct = (value) => {
    dispatch(crmPageActions.selectedInquiryProduct(value));
  };

  // モーダル用
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleOpen2 = async () => {
    addLoading();
    // 電話番号などの特殊キーの値をフォーマットする
    const params = formatRequestSpKey(detail);
    try {
      // responseAtとeffectAtを同じ値でリクエストする
      const { effectAt, acquisitionId } = params;
      params.responseAt = effectAt;
      // 獲得者IDのパラメータ名が違うためここで追加、置き換え
      params.acquisitionUserId = acquisitionId;
      delete params.acquisitionId;

      const {
        status,
        data,
        message,
        description,
      } = await customerRegisterApi(params);

      const isGoogleChatError = () => {
        return status === 200
          && message === 'Forbidden'
          && /へのGoogleチャット通知に失敗しました。/.test(description);
      };

      if (isGoogleChatError()) {
        const apiMsg = {
          status: 'success',
          message: 'Created',
          messageList: ['登録に成功しましたが、', description],
        };
        dispatch(isOpen(apiMsg));
        onCancelRegisterWindow();
        removeLoading();
        return;
      }

      searchCustomerByKeyword(detail, ['lastName', 'firstName'], true);
      // 顧客IDを続けて編集するように仮反映（しない場合は後で破棄される）
      tmpCustomerId.current = data?.customerId;
      // 登録
      dispatch(crmPageActions.completedCreateResponseRegister(data));
      setOpen2(true);
    } catch (e) {
      console.error(e);
      // ...
    }
    removeLoading();
  };
  // 問い合わせ物件削除確認モダールを表示する
  const onInquiryRemove = (data) => {
    selectedInquiryRef.current = data;
    setOpen3(true);
  };
  // 編集破棄ウィンドウを閉じる
  const handleClose1 = () => setOpen1(false);
  // 登録ウィンドウを閉じる
  const handleClose2 = () => setOpen2(false);
  // 削除ウィンドウを閉じる
  const handleClose3 = () => setOpen3(false);
  const datas = [
    {
      ttl: '編集内容を破棄',
      txt: '現在編集中の顧客情報は破棄されます。よろしいですか？',
      onOk: destructionStart,
      onCancel: () => {},
    },
    {
      ttl: '登録しました',
      txt: '登録しました。引き続き編集しますか？',
      onOk: onOkRegisterWindow,
      onCancel: onCancelRegisterWindow,
    },
    {
      ttl: '削除します',
      txt: '本当に削除してよろしいですか？ ',
      onOk: deleteStart,
      onCancel: () => {},
    },
  ];

  const getButton = () => {
    const className = classNames(
      baseClasses.buttonsPrimary,
      classes.btn2,
      disabled || errorSet.size !== 0 ? 'disabled' : null,
    );
    switch (updateType) {
      case UPDATE_TYPE_CODE.CUSTOMER_UPDATE:
        return (
          <Button
            className={className}
            onClick={() => verifyMail(detail, keepStart, cancelMailChange)}
          >
            保存
          </Button>
        );
      case UPDATE_TYPE_CODE.RETURN:
        return (
          <Button
            className={className}
            onClick={() => verifyMail(detail, returnStart, cancelMailChange)}
          >
            戻し
          </Button>
        );
      case UPDATE_TYPE_CODE.REBELLION:
        return (
          <Button
            className={className}
            onClick={() => verifyMail(detail, rebellionStart, cancelMailChange)}
          >
            再反
          </Button>
        );
      default:
        return (
          <Button className={className} onClick={handleOpen2}>
            登録
          </Button>
        );
    }
  };

  return (
    <Grid className={classes.root}>
      <Grid className={classes.content}>
        <Typography className={`${baseClasses.title2} ${classes.header}`}>
          新規反響登録
        </Typography>
        <Grid className={`${classes.contentId} ${id && classes.clicable}`} onClick={id && searchByCustomerId}>
          <Typography className={baseClasses.title4}>顧客ID</Typography>
          <Typography className={classes.contentIdNo}>{id || '-'}</Typography>
        </Grid>
        <ResponseRegisterName
          user={user}
          detail={detail}
          setDetail={setDetail}
          getComponent={getComponent}
          searchCustomerByKeyword={searchCustomerByKeyword}
          errorCallback={errorCallback}
        />
        <ResponseRegisterContact
          user={user}
          detail={detail}
          setDetail={setDetail}
          searchCustomerByKeyword={searchCustomerByKeyword}
          key={rerenderKey}
          errorCallback={errorCallback}
        />
        <ResponseRegisterAddress
          user={user}
          detail={detail}
          setDetail={setDetail}
          getComponent={getComponent}
          searchCustomerByKeyword={searchCustomerByKeyword}
          errorCallback={errorCallback}
        />
        {isAllCustomerAdmin && (
          <ResponseRegisterFamily
            user={user}
            detail={detail}
            setDetail={setDetail}
            errorCallback={errorCallback}
          />
        )}
        {isAllCustomerAdmin && (
          <ResponseRegisterWorkPlace
            user={user}
            detail={detail}
            setDetail={setDetail}
            getComponent={getComponent}
            errorCallback={errorCallback}
          />
        )}
        {isAllCustomerAdmin && (
          <ResponseRegisterBudget
            user={user}
            detail={detail}
            setDetail={setDetail}
            errorCallback={errorCallback}
          />
        )}
        {isAllCustomerAdmin && (
          <ResponseRegisterHope
            user={user}
            detail={detail}
            setDetail={setDetail}
            getComponent={getComponent}
            errorCallback={errorCallback}
          />
        )}
        {isAllCustomerAdmin && (
          <ResponseRegisterPresentCondition
            user={user}
            detail={detail}
            setDetail={setDetail}
            getComponent={getComponent}
            errorCallback={errorCallback}
          />
        )}
        <ResponseRegisterResponse
          user={user}
          detail={detail}
          setDetail={setDetail}
          inquiry={inquiry}
          response={response}
          getComponent={getComponent}
          onInquiryRemove={onInquiryRemove}
          isAllCustomerAdmin={isAllCustomerAdmin}
          updateType={updateType}
          searchByWebMemberId={searchByWebMemberId}
          registerInquiryProduct={registerInquiryProduct}
          isNew={!id}
          reactionDay={reactionDay}
          setReactionDay={setReactionDay}
          customerId={id}
        />
        {isAllCustomerAdmin && (
          <ResponseRegisterInHouse
            user={user}
            detail={detail}
            setDetail={setDetail}
            getComponent={getComponent}
            isLeadersMemoUse={isLeadersMemoUse}
            isClaim={isClaim}
            isNew={!id}
            customerId={!id ? null : id}
          />
        )}
      </Grid>
      <Grid className={classes.footer}>
        <Button className={classes.btn1} onClick={handleOpen1}>
          編集内容を破棄
        </Button>
        {getButton()}
        <ResponseRegisterConfirm
          open={open1}
          onClose={handleClose1}
          data={datas[0]}
        />
        <ResponseRegisterConfirm
          open={open2}
          onClose={handleClose2}
          data={datas[1]}
        />
        <ResponseRegisterConfirm
          open={open3}
          onClose={handleClose3}
          data={datas[2]}
        />
      </Grid>
    </Grid>
  );
}
