/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-tabs */
import { memo } from 'react';
import { CKEditor } from 'ckeditor4-react';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  wrap: {
    width: '74.5%',
  },
  full: {
    width: '100%',
  },
}));

function MailTitleAndBody(props) {
  const {
    initData = '',
    setMailBody = () => {},
    disable = false,
    full = false,
    getOwnLibrary = () => {},
    isActiveSaveTemplate = false, // テンプレ保存ボタンの表示/非表示
    isLibraryOpen = { create: false, edit: false },
    setIsLibraryOpen = () => {},
  } = props;
  const classes = useStyles();

  // テンプレ保存ボタンのロジック
  // プラグイン側のsavetemplateと連動しているため注意
  const handlePluginLoaded = (e) => {
    e?.editor?.addCommand('savetemplate', {
      readOnly: true, // 読み取りモードでも押せるようにする
      exec: async () => {
        if (!isActiveSaveTemplate) return;
        await getOwnLibrary();
        setIsLibraryOpen({ ...isLibraryOpen, create: true });
      },
    });
  };

  // memo: onBlurの場合、以下の理由で期待される動作にならなかった
  // onBlur->onClick と走るものの、useStateの更新が反映される前の為に登録内容と表示に差異が出る
  const handleChange = (event) => {
    const data = event?.editor?.getData?.() ?? '';
    setMailBody(data);
  };

  const handleLoaded = () => {
    // 下記Issueにて、CKEditor4にはmodal等で"tabIndex='-1'"が設定されている場合、input要素が入力できない問題が挙げられている
    // Material UI側ではPropsによる設定ができなかった為、querySelectorで暫定対応
    // ref: Can't type in fields · Issue #3555 · ckeditor/ckeditor4 · GitHub
    // link: https://github.com/ckeditor/ckeditor4/issues/3555
    const muiModal = document.getElementsByClassName(
      'MuiDialog-container MuiDialog-scrollPaper',
    )?.[0];
    muiModal?.removeAttribute('tabindex');
  };

  return (
    <Grid item className={full ? classes.full : classes.wrap}>
      <CKEditor
        config={!isActiveSaveTemplate ? { removeButtons: 'Anchor,Paste,savetemplate' } : ''}
        initData={initData}
        readOnly={disable}
        onChange={handleChange}
        onLoaded={handleLoaded}
        onPluginsLoaded={handlePluginLoaded}
        editorUrl="/CKEditor/ckeditor.js"
        onMode={(e) => {
          if (e.editor?.mode === 'source') {
            const editable = e.editor.editable();
            editable.attachListener(editable, 'input', (event) => {
              setMailBody(event.data.$.target.value);
            });
          }
        }} // ソース編集モードでonChangeが動かないためinputイベントで監視
      />
    </Grid>
  );
}

export default memo(MailTitleAndBody, () => true);
