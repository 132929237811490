export const ACTION_DETAIL_CALL = 1;
export const ACTION_DETAIL_CEL_PHONE_CALL = 2;
export const ACTION_DETAIL_RECEIVE_CALL = 3;
export const ACTION_DETAIL_CARE_TALKING = 4;
export const ACTION_DETAIL_UNREACHABLE = 5;
export const ACTION_DETAIL_SEND_MAIL = 6;
export const ACTION_DETAIL_RECEIVE_MAIL = 7;
export const ACTION_DETAIL_MAILING = 8;
export const ACTION_DETAIL_POSTCARD = 9;
export const ACTION_DETAIL_VISIT_GUIDANCE = 10;
export const ACTION_DETAIL_VISIT_COMPANY = 11;
export const ACTION_DETAIL_APPOINTMENT = 12;
export const ACTION_DETAIL_CONTRACT_COMPLETED = 13;
export const ACTION_DETAIL_CUSTOMER_CARD = 14;
export const ACTION_DETAIL_REGISTER = 15;
export const ACTION_DETAIL_ERROR = 16;
export const ACTION_DETAIL_BLANK_FAX = 17;
export const ACTION_DETAIL_WEB = 18;
export const ACTION_DETAIL_MANUAL = 19;
export const ACTION_DETAIL_CANCELLATION = 20;
export const ACTION_DETAIL_TERMINATION = 21;
export const ACTION_DETAIL_INQUIRY = 22;
export const ACTION_DETAIL_BUSINESS_CONTACT = 31;
export const ACTION_DETAIL_INTERIM_MONEY_GUIDE = 32;
export const ACTION_DETAIL_DESIGN_CHANGE_CONTRACT = 33;
export const ACTION_DETAIL_PRIOR_REVIEW_DOCUMENTS = 34;
export const ACTION_DETAIL_APPLICATION_FORM = 35;
export const ACTION_DETAIL_ENTRY_GUIDE = 36;
export const ACTION_DETAIL_PREVIEW_INFORMATION = 37;
export const ACTION_DETAIL_LOAN_CONSULTATION = 38;
export const ACTION_DETAIL_REMAINING_BALANCE_AND_OTHER_EXPENSES = 39;
export const ACTION_DETAIL_INDIVIDUAL_SEND = 41;
export const ACTION_DETAIL_INDIVIDUAL_TIMER = 42;
export const ACTION_DETAIL_SIMULTANEOUS_SEND = 43;
export const ACTION_DETAIL_SIMULTANEOUS_TIMER = 44;
export const ACTION_DETAIL_INDIVIDUAL_SEND_M = 46;
export const ACTION_DETAIL_INDIVIDUAL_TIMER_M = 47;
export const ACTION_DETAIL_SIMULTANEOUS_SEND_M = 48;
export const ACTION_DETAIL_SIMULTANEOUS_TIMER_M = 49;
export const ACTION_DETAIL_INDIVIDUAL_SEND_STEAD = 51;
export const ACTION_DETAIL_INDIVIDUAL_TIMER_STEAD = 52;
export const ACTION_DETAIL_SIMULTANEOUS_SEND_STEAD = 53;
export const ACTION_DETAIL_SIMULTANEOUS_TIMER_STEAD = 54;
export const ACTION_DETAIL_INDIVIDUAL_SEND_M__STEAD = 56;
export const ACTION_DETAIL_INDIVIDUAL_TIMER_M_STEAD = 57;
export const ACTION_DETAIL_SIMULTANEOUS_SEND_M_STEAD = 58;
export const ACTION_DETAIL_SIMULTANEOUS_TIMER_M_STEAD = 59;
export const ACTION_DETAIL_INDIVIDUAL_SEND_I = 61;
export const ACTION_DETAIL_INDIVIDUAL_TIMER_I = 62;
export const ACTION_DETAIL_SIMULTANEOUS_SEND_I = 63;
export const ACTION_DETAIL_SIMULTANEOUS_TIMER_I = 64;
export const ACTION_DETAIL_INDIVIDUAL_SEND_M_I = 66;
export const ACTION_DETAIL_INDIVIDUAL_TIMER_M_I = 67;
export const ACTION_DETAIL_SIMULTANEOUS_SEND_M_I = 68;
export const ACTION_DETAIL_SIMULTANEOUS_TIMER_M_I = 69;
export const ACTION_DETAIL_CLAIM = 71;
export const ACTION_DETAIL_PROPERTY_DATA = 72;
export const ACTION_DETAIL_WARRANTY = 73;
export const ACTION_DETAIL_SURVEY = 74;
export const ACTION_DETAIL_FP_CONSULTATION = 75;
export const ACTION_DETAIL_FIRE_INSURANCE = 76;
export const ACTION_DETAIL_SNEAK_PREVIEW = 77;
export const ACTION_DETAIL_CONFIRMATION_MEETING = 78;
export const ACTION_DETAIL_HANDOVER_PARTY = 79;
export const ACTION_DETAIL_NAME_CHANGE = 80;
export const ACTION_DETAIL_PAYMENT_CHANGE = 81;
export const ACTION_DETAIL_CHANGING_FAMILY_NAME = 82;
export const ACTION_DETAIL_CESSATION = 83;
export const ACTION_DETAIL_EXPRESS = 86;
export const ACTION_DETAIL_CUSTOMER_COMBINATION = 91;
export const ACTION_DETAIL_RELATION = 92;
export const ACTION_DETAIL_CANCEL = 93;
export const ACTION_DETAIL_OTHER = 99;
export const ACTION_DETAIL_MAIL_FAILS = 111;
export const ACTION_DETAIL_MAIL_BLOCK = 112;
export const ACTION_DETAIL_URL_CLICK = 113;
export const ACTION_DETAIL_UNSUBSCRIBE = 114;
export const ACTION_DETAIL_AGENT_MAIL = 115;
export const ACTION_DETAIL_NO_GUIDE = 121;
export const ACTION_DETAIL_DOCUMENT = 122;
export const ACTION_DETAIL_SOURCE_CONTACT = 123;
export const ACTION_DETAIL_PORTER = 124;
export const ACTION_DETAIL_CEL_PHONE_RECEIVE_CALL = 131;
export const ACTION_DETAIL_CEL_PHONE_CARE_TALKING = 132;
export const ACTION_DETAIL_CEL_PHONE_UNREACHABLE = 133;
export const ACTION_DETAIL_SEND_SMS = 141;
export const ACTION_DETAIL_RECEIVE_SMS = 142;
export const ACTION_DETAIL_LINE_SEND = 143;
export const ACTION_DETAIL_LINE_RECEIVE = 144;
export const ACTION_DETAIL_UPDATE = 151;
export const ACTION_DETAIL_SALESFLYER = 161;

// メール送信画面からの登録
export const ACTION_DETAILE_MAIL_GROUP = [
  ACTION_DETAIL_INDIVIDUAL_SEND,
  ACTION_DETAIL_INDIVIDUAL_TIMER,
  ACTION_DETAIL_SIMULTANEOUS_SEND,
  ACTION_DETAIL_SIMULTANEOUS_TIMER,
  ACTION_DETAIL_INDIVIDUAL_SEND_M,
  ACTION_DETAIL_INDIVIDUAL_TIMER_M,
  ACTION_DETAIL_SIMULTANEOUS_SEND_M,
  ACTION_DETAIL_SIMULTANEOUS_TIMER_M,
  ACTION_DETAIL_INDIVIDUAL_SEND_STEAD,
  ACTION_DETAIL_INDIVIDUAL_TIMER_STEAD,
  ACTION_DETAIL_SIMULTANEOUS_SEND_STEAD,
  ACTION_DETAIL_SIMULTANEOUS_TIMER_STEAD,
  ACTION_DETAIL_INDIVIDUAL_SEND_M__STEAD,
  ACTION_DETAIL_INDIVIDUAL_TIMER_M_STEAD,
  ACTION_DETAIL_SIMULTANEOUS_SEND_M_STEAD,
  ACTION_DETAIL_SIMULTANEOUS_TIMER_M_STEAD,
  ACTION_DETAIL_INDIVIDUAL_SEND_I,
  ACTION_DETAIL_INDIVIDUAL_TIMER_I,
  ACTION_DETAIL_SIMULTANEOUS_SEND_I,
  ACTION_DETAIL_SIMULTANEOUS_TIMER_I,
  ACTION_DETAIL_INDIVIDUAL_SEND_M_I,
  ACTION_DETAIL_INDIVIDUAL_TIMER_M_I,
  ACTION_DETAIL_SIMULTANEOUS_SEND_M_I,
  ACTION_DETAIL_SIMULTANEOUS_TIMER_M_I,
];

// 対応履歴からの登録
export const ACTION_DETAIL_TAIO_RIREKI_GROUP = [
  ACTION_DETAIL_SEND_MAIL,
  ACTION_DETAIL_RECEIVE_MAIL,
];

export const ACTION_DETAIL_CODE = {
  [ACTION_DETAIL_CALL]: '架電（固定）',
  [ACTION_DETAIL_CEL_PHONE_CALL]: '架電（携帯）',
  [ACTION_DETAIL_RECEIVE_CALL]: '受電（固定）',
  [ACTION_DETAIL_CARE_TALKING]: '留守電（固定）',
  [ACTION_DETAIL_UNREACHABLE]: '不通（固定）',
  [ACTION_DETAIL_SEND_MAIL]: '送信',
  [ACTION_DETAIL_RECEIVE_MAIL]: '受信',
  [ACTION_DETAIL_MAILING]: '郵送',
  [ACTION_DETAIL_POSTCARD]: 'はがき',
  [ACTION_DETAIL_VISIT_GUIDANCE]: '来案(来社&案内)',
  [ACTION_DETAIL_VISIT_COMPANY]: '訪案(訪問&案内)',
  [ACTION_DETAIL_APPOINTMENT]: '待案(待合せ&案内)',
  [ACTION_DETAIL_CONTRACT_COMPLETED]: '契約完了',
  [ACTION_DETAIL_CUSTOMER_CARD]: 'お客様カード',
  [ACTION_DETAIL_REGISTER]: '登録',
  [ACTION_DETAIL_ERROR]: 'エラー',
  [ACTION_DETAIL_BLANK_FAX]: 'FAX送付状_空',
  [ACTION_DETAIL_WEB]: 'WEB',
  [ACTION_DETAIL_CANCELLATION]: '解除',
  [ACTION_DETAIL_MANUAL]: '手動',
  [ACTION_DETAIL_TERMINATION]: '解約',
  [ACTION_DETAIL_INQUIRY]: '問合わせ',
  [ACTION_DETAIL_BUSINESS_CONTACT]: '業務連絡',
  [ACTION_DETAIL_INTERIM_MONEY_GUIDE]: '中間金案内',
  [ACTION_DETAIL_DESIGN_CHANGE_CONTRACT]: '設計変更契約書',
  [ACTION_DETAIL_PRIOR_REVIEW_DOCUMENTS]: '事前審査書類',
  [ACTION_DETAIL_APPLICATION_FORM]: '本申込書類',
  [ACTION_DETAIL_ENTRY_GUIDE]: '入説案内',
  [ACTION_DETAIL_PREVIEW_INFORMATION]: '内覧会案内',
  [ACTION_DETAIL_LOAN_CONSULTATION]: 'ローン相談会',
  [ACTION_DETAIL_REMAINING_BALANCE_AND_OTHER_EXPENSES]:
    '残代金・諸費用のご案内',
  [ACTION_DETAIL_INDIVIDUAL_SEND]: '個別即時',
  [ACTION_DETAIL_INDIVIDUAL_TIMER]: '個別ﾀｲﾏｰ',
  [ACTION_DETAIL_SIMULTANEOUS_SEND]: '一斉即時',
  [ACTION_DETAIL_SIMULTANEOUS_TIMER]: '一斉ﾀｲﾏｰ',
  [ACTION_DETAIL_INDIVIDUAL_SEND_M]: '個別即時M',
  [ACTION_DETAIL_INDIVIDUAL_TIMER_M]: '個別ﾀｲﾏｰM',
  [ACTION_DETAIL_SIMULTANEOUS_SEND_M]: '一斉即時M',
  [ACTION_DETAIL_SIMULTANEOUS_TIMER_M]: '一斉ﾀｲﾏｰM',
  [ACTION_DETAIL_INDIVIDUAL_SEND_STEAD]: '個別即時㈹',
  [ACTION_DETAIL_INDIVIDUAL_TIMER_STEAD]: '個別ﾀｲﾏｰ㈹',
  [ACTION_DETAIL_SIMULTANEOUS_SEND_STEAD]: '一斉即時㈹',
  [ACTION_DETAIL_SIMULTANEOUS_TIMER_STEAD]: '一斉ﾀｲﾏｰ㈹',
  [ACTION_DETAIL_INDIVIDUAL_SEND_M__STEAD]: '個別即時M㈹',
  [ACTION_DETAIL_INDIVIDUAL_TIMER_M_STEAD]: '個別ﾀｲﾏｰM㈹',
  [ACTION_DETAIL_SIMULTANEOUS_SEND_M_STEAD]: '一斉即時M㈹',
  [ACTION_DETAIL_SIMULTANEOUS_TIMER_M_STEAD]: '一斉ﾀｲﾏｰM㈹',
  [ACTION_DETAIL_INDIVIDUAL_SEND_I]: '個別即時(I)',
  [ACTION_DETAIL_INDIVIDUAL_TIMER_I]: '個別ﾀｲﾏｰ(I)',
  [ACTION_DETAIL_SIMULTANEOUS_SEND_I]: '一斉即時(I)',
  [ACTION_DETAIL_SIMULTANEOUS_TIMER_I]: '一斉ﾀｲﾏｰ(I)',
  [ACTION_DETAIL_INDIVIDUAL_SEND_M_I]: '個別即時M(I)',
  [ACTION_DETAIL_INDIVIDUAL_TIMER_M_I]: '個別ﾀｲﾏｰM(I)',
  [ACTION_DETAIL_SIMULTANEOUS_SEND_M_I]: '一斉即時M(I)',
  [ACTION_DETAIL_SIMULTANEOUS_TIMER_M_I]: '一斉ﾀｲﾏｰM(I)',
  [ACTION_DETAIL_CLAIM]: 'クレーム',
  [ACTION_DETAIL_PROPERTY_DATA]: '物件資料',
  [ACTION_DETAIL_WARRANTY]: '保証証書',
  [ACTION_DETAIL_SURVEY]: 'アンケート',
  [ACTION_DETAIL_FP_CONSULTATION]: 'ＦＰ相談',
  [ACTION_DETAIL_FIRE_INSURANCE]: '火災保険',
  [ACTION_DETAIL_SNEAK_PREVIEW]: '内覧会',
  [ACTION_DETAIL_CONFIRMATION_MEETING]: '確認会',
  [ACTION_DETAIL_HANDOVER_PARTY]: 'お引渡会',
  [ACTION_DETAIL_NAME_CHANGE]: '名義変更',
  [ACTION_DETAIL_PAYMENT_CHANGE]: '支払変更',
  [ACTION_DETAIL_CHANGING_FAMILY_NAME]: '改姓',
  [ACTION_DETAIL_CESSATION]: '不通',
  [ACTION_DETAIL_EXPRESS]: '速達',
  [ACTION_DETAIL_CUSTOMER_COMBINATION]: '顧客結合',
  [ACTION_DETAIL_RELATION]: 'リレーション',
  [ACTION_DETAIL_CANCEL]: 'キャンセル',
  [ACTION_DETAIL_OTHER]: 'その他',
  [ACTION_DETAIL_MAIL_FAILS]: 'メール不着',
  [ACTION_DETAIL_MAIL_BLOCK]: 'ﾒｰﾙﾌﾞﾛｯｸ',
  [ACTION_DETAIL_URL_CLICK]: 'URLクリック',
  [ACTION_DETAIL_NO_GUIDE]: '来社のみ',
  [ACTION_DETAIL_DOCUMENT]: '資料渡し',
  [ACTION_DETAIL_SOURCE_CONTACT]: '源泉接触',
  [ACTION_DETAIL_PORTER]: 'ポーター接触',
  [ACTION_DETAIL_CEL_PHONE_RECEIVE_CALL]: '受電（携帯）',
  [ACTION_DETAIL_CEL_PHONE_CARE_TALKING]: '留守電（携帯）',
  [ACTION_DETAIL_CEL_PHONE_UNREACHABLE]: '不通（携帯）',
  [ACTION_DETAIL_SEND_SMS]: 'SMS送信',
  [ACTION_DETAIL_RECEIVE_SMS]: 'SMS受信',
  [ACTION_DETAIL_LINE_SEND]: 'LINE送信',
  [ACTION_DETAIL_LINE_RECEIVE]: 'LINE受信',
  [ACTION_DETAIL_UPDATE]: '更新',
  [ACTION_DETAIL_SALESFLYER]: '源泉',
  [ACTION_DETAIL_UNSUBSCRIBE]: '配信停止',
  [ACTION_DETAIL_AGENT_MAIL]: '代行配信',
};
