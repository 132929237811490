import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useState } from 'react';

import { IS_SHAIN } from '../../../constants';

import store from '../../../store/index';

import gensenStyle from './gensenRegisterStyles';
import commonStyles from '../../styles';

import UserTree from '../../common/userTreeNew';
import DraggableDialog from '../../eleCommon/draggableDialog';
import CustomSimpleDrawer from '../../eleCommon/sp/customSimpleDrawer';

import UserTypeList from './userTypeList';

export const getDisplayAcquisitionName = (data, defaultTxt = null) => {
  const isShain = Number(data.acquisitionCode) === IS_SHAIN;
  const displayAcquisitionName = isShain
    ? [data.acquisitionUserFirstName, data.acquisitionUserLastName].filter(Boolean).join(' ')
    : data.acquisitionMemo;
  return displayAcquisitionName || defaultTxt;
};

export default function AddAcquisition(props) {
  const { onClose, local, setLocal } = props;
  const gCommon = gensenStyle();
  const common = commonStyles();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [label, setLabel] = useState('');
  const [selectUser, setSelectUser] = useState({
    userId: local.acquisitionUserId,
    userName: local.acquisitionUserFirstName,
  });
  const setValue = (e) => {
    setLocal({
      ...local,
      acquisitionCode: e,
      userCode: label,
    });
    if (e !== 1) {
      setSelectUser({});
      setOpen2(true);
    }
  };
  const onClick = () => {
    setLocal({
      ...local,
      acquisitionUserId: selectUser.userId,
      acquisitionUserFirstName: selectUser.userName,
      acquisitionCode: 1,
      userCode: '社員',
    });
    onClose();
  };
  const setSelf = () => {
    const { responseHeader } = store.getState();
    setLocal({
      ...local,
      acquisitionUserId: responseHeader.userId,
      acquisitionUserFirstName: responseHeader.userName,
      acquisitionUserLastName: '',
      acquisitionCode: 1,
      userCode: '社員',
    });
    onClose();
  };
  // テキスト
  const [txt, setTxt] = useState(getDisplayAcquisitionName(local));
  const onChange = (e) => {
    setTxt(e.target.value);
  };
  const onSaveName = () => {
    setLocal({
      ...local,
      acquisitionUserId: '',
      acquisitionMemo: txt,
      userCode: label,
    });
    setOpen2(false);
    onClose();
  };
  return (
    <Grid className={gCommon.root}>
      <Grid className={common.header}>
        <Typography className={`${common.title2} ${gCommon.title}`}>獲得者選択</Typography>
        <Close className={gCommon.closeIcon} onClick={onClose} />
      </Grid>
      <Button
        className={gCommon.button}
        onClick={setSelf}
      >
        自分
      </Button>
      <Button
        className={gCommon.button}
        onClick={() => { return setOpen(true); }}
      >
        アカウントなし{label ? `（${label}）` : null}
      </Button>
      <CustomSimpleDrawer
        open={open}
        setOpen={setOpen}
        title="区分"
        list={
          <UserTypeList
            setValue={setValue}
            label={label}
            setLabel={setLabel}
            setOpen={setOpen}
          />
        }
      />
      <DraggableDialog
        open={open2}
        onClose={() => { return setOpen2(false); }}
      >
        <Grid className={gCommon.root}>
          <Grid className={common.header}>
            <Typography className={`${common.title2} ${gCommon.title}`}>{label}</Typography>
            <Close className={gCommon.closeIcon} onClick={onClose} />
          </Grid>
          <Grid container justifyContent="space-around">
            <TextField
              value={txt}
              onChange={(e) => { return onChange(e); }}
            />
          </Grid>
          <Grid container justifyContent="center" style={{ marginTop: 32 }}>
            <Button
              className={common.buttonsPrimary}
              onClick={onSaveName}
            >
              保存
            </Button>
          </Grid>
        </Grid>
      </DraggableDialog>
      <UserTree
        selectUser={selectUser}
        setSelectUser={setSelectUser}
        selectDivision={[]}
        setSelectDivision={() => {}}
        isSelectUserWindow
      />
      <Grid container justifyContent="center" style={{ marginTop: 32 }}>
        <Button
          className={common.buttonsPrimary}
          onClick={onClick}
        >
          選択
        </Button>
      </Grid>
    </Grid>
  );
}
