import { useState, useEffect } from 'react';
import store from '../../../../store';
import UserTree from '../../userTreeNew';
import { ACQUISITION_CODE, IS_SHAIN } from '../../../../constants';

function SelectAcquisitionWindow(props) {
  const {
    isOpen,
    setIsOpen = () => {},
    onSelect,
    initialMemo,
  } = props;

  const { responseHeader } = store.getState();

  const [selectUser, setSelectUser] = useState({});
  const [selectDivision, setSelectDivision] = useState({});

  const [displayName, setDisplayName] = useState(initialMemo ?? responseHeader.userName);
  const [initialAcquirer, setInitialAcquirer] = useState(String(IS_SHAIN));

  const initial = {
    acquisitionId: null,
    name: '',
    acquisitionCode: null,
    acquisitionName: null,
  };

  const handleSelectFunction = () => {
    // 社員のとき、userIdが空にならないように
    if (Number(initialAcquirer) === IS_SHAIN && !selectUser?.userId) return;
    // 獲得者
    onSelect({
      acquisitionId: Number(initialAcquirer) === IS_SHAIN ? selectUser.userId : null,
      name: Number(initialAcquirer) === IS_SHAIN ? selectUser.userName : displayName,
      acquisitionCode: initialAcquirer,
      acquisitionName: ACQUISITION_CODE[Number(initialAcquirer)],
    });
  };

  const handleClearFunction = () => {
    setDisplayName(responseHeader.userName);
    onSelect(initial);
  };

  useEffect(() => {
    if (!isOpen) return;
    // 初期条件の追加
    setSelectUser({
      userId: responseHeader.userId,
      userName: responseHeader.userName,
      divisionId: responseHeader?.mainDivision.divisionId,
      divisionName: responseHeader?.mainDivision.divisionName,
    });
  }, [isOpen]);

  return (
    <UserTree
      selectUser={selectUser}
      setSelectUser={setSelectUser}
      selectDivision={selectDivision}
      setSelectDivision={setSelectDivision}
      isOpenTree={isOpen}
      setIsOpenTree={setIsOpen}
      handleSelectFunction={handleSelectFunction}
      handleClearFunction={handleClearFunction}
      isDialog
      isSelectUserWindow
      isAcquirer
      initialAcquirer={initialAcquirer}
      setInitialAcquirer={setInitialAcquirer}
      setDisplayName={setDisplayName}
      defaultDisplayName={displayName}
    />
  );
}

export default SelectAcquisitionWindow;
