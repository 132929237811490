import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
} from '@material-ui/core';
import commonTheme from '../../styles/theme';
import CustomerStatusLabel from './customerStatusLabel';
import CustomerId from './customerId';
import CustomerResponsible from './customerResponsible';
import CustomerResponse from './customerResponse';
import CustomerHistory from './customerHistory';
import CustomerButtonIcon from './customerButtonIcon';
// import CustomerObi from './customerObi';
import CustomerName from './customerName1';
// import CustomerName2 from './customerName2';
import CustomerAddress from './customerAddress';
import CustomerWorkPlace from './customerWorkPlace';
import CustomerExamination from './customerExamination';
import CustomerStatus from './customerStatus';
import CustomerRank from './customerRank';
import CustomerGuidance from './customerGuidance';
import CustomerTel from './customerTel';
import CustomerMail from './customerMail';
import CustomerTime from './customerTime';
import CustomerHoliday from './customerHoliday';
import CustomerInfo1 from './customerInfo1';
import CustomerInfo2 from './customerInfo2';
import CustomerArea from './customerArea';
import CustomerDrive from './customerDrive';
import CustomerInstructions from './customerInstructions';
import CustomerOverview from './customerOverview';
import CustomerMemo from './customerMemo';
import CustomerLeaderMemo from './customerLeaderMemo';
import { HolidayTimeProvider } from './holidayTimeContext';
import { useStore } from '../../../hooks';
import { useCustomerMain } from '../../../containers/customerMain';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    background: '#F3F3F3',
    border: '1px solid #C8C8C8',
    // marginBottom: 40,
    [commonTheme.breakpoints.up('md')]: {
      width: 1234,
      padding: '12px 16px 8px',
    },
  },
  wrap1: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 6,
      alignItems: 'stretch',
    },
  },
  wrap2: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 6,
    },
  },
  wrap3: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  },
  wrap4: {
    [commonTheme.breakpoints.up('md')]: {
      width: 874,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  wrap5: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  wrap6: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  block1: {
    [commonTheme.breakpoints.up('md')]: {
      width: 458,
    },
  },
  block2: {
    [commonTheme.breakpoints.up('md')]: {
      width: 400,
    },
  },
  block4: {
    [commonTheme.breakpoints.up('md')]: {
      width: 312,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  box: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
    '&:not(:last-child)': {
      marginBottom: 6,
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
    marginBottom: 6,
  },
  rowBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  arrow: {
    fontSize: 16,
    color: theme.palette.primaryColor,
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: 'auto',
    padding: '16px',
    '& .MuiSvgIcon-root': {
      boxSizing: 'border-box',
      width: '16px',
      height: '24px',
    },
  },
}));

function CustomerMainItem(props) {
  const {
    customer,
    getComponent,
    getParts,
    getEditDialog,
    isCloesdCustomerDetail,
  } = props;

  const {
    customerDetail,
    setCustomerDetail,
    setCustomerMail,
    customerMail,
    updatePage,
    rerenderKey,
  } = useCustomerMain();

  const classes = useStyles();

  const { responseHeader } = useStore();
  const { roleIds } = responseHeader;

  const [customerInfo, setCustomerInfo] = useState({
    userLevel: customer.userLevel,
    emailStatusCode: customer.emailStatusCode,
    isMailSend1: customer.isMailSend1,
    isMailSend2: customer.isMailSend2,
    isMailSend3: customer.isMailSend3,
    isMailSend4: customer.isMailSend4,
    telStatusCode: customer.telStatusCode,
  });

  const data = {
    customer,
    component: getComponent,
    parts: getParts,
    editDialog: getEditDialog,
    update: updatePage,
    customerDetail,
    setCustomerDetail,
    customerMail,
    setCustomerMail,
    customerInfo,
    setCustomerInfo,
    isCloesdCustomerDetail,
  };

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      setCustomerInfo({
        ...customerInfo,
        userLevel: customer.userLevel,
        emailStatusCode: customer.emailStatusCode,
        isMailSend1: customer.isMailSend1,
        isMailSend2: customer.isMailSend2,
        isMailSend3: customer.isMailSend3,
        isMailSend4: customer.isMailSend4,
        telStatusCode: customer.telStatusCode,
      });
    } else {
      isFirstRender.current = false;
    }
  }, [customer]);

  return (
    <Grid className={classes.root} key={rerenderKey}>
      <CustomerStatusLabel customer={customer} />
      <Grid className={classes.wrap1}>
        <CustomerId customerId={customer.customerId} />
        <CustomerResponsible input={data} />
        <CustomerResponse input={data} />
      </Grid>
      <Grid className={classes.wrap2}>
        <CustomerHistory input={data} />
        <CustomerButtonIcon input={data} />
        {/* <CustomerObi input={data} /> */}
      </Grid>
      <Grid className={classes.wrap3}>
        <Grid className={classes.wrap4}>
          <Grid className={classes.wrap5}>
            <Grid className={classes.block1}>
              <CustomerName input={data} />
              <CustomerName input={data} isSub />
              {/* <CustomerName2 input={data} /> */}
              <Grid className={classes.row}>
                <CustomerAddress input={data} />
              </Grid>
              <Grid className={classes.box}>
                <CustomerWorkPlace input={data} />
              </Grid>
              <CustomerExamination input={data} />
            </Grid>
            <Grid className={classes.block2}>
              <Grid className={classes.rowBox}>
                <CustomerStatus input={data} />
                <CustomerRank input={data} />
              </Grid>
              <CustomerGuidance input={data} />
              <CustomerTel input={data} />
              <CustomerMail input={data} />
              <HolidayTimeProvider customer={customer}>
                <CustomerTime />
                <CustomerHoliday />
              </HolidayTimeProvider>
            </Grid>
          </Grid>
          <Grid className={classes.block3}>
            <CustomerInfo1 input={data} />
            <CustomerInfo2 input={data} />
            <Grid className={classes.box}>
              <CustomerArea input={data} />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.block4}>
          <CustomerDrive input={data} />
          <CustomerInstructions input={data} />
          <CustomerOverview input={data} />
          <CustomerMemo input={data} roleIds={roleIds} />
          {/* 上長メモ利用 */}
          {roleIds.includes(13) && (
            <CustomerLeaderMemo input={data} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CustomerMainItem;
